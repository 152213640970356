.input-container input{
    background: #FFFFFF;
    border: 1px solid #C7C7C7;
    box-sizing: border-box;
    border-radius: 23px;
    padding: 13px 20px 13px 20px;
    width: 376px;
    height: 46px;
}

.input-container img{
    position: absolute;
    top: 30%;
    left: 90%;
}

.select-container .ant-select-selector{
    border-radius: 23px !important;
    padding: 10px 20px 10px 20px !important;
    width: 100% !important;
    min-width: 200px !important;
    height: 46px !important;
    align-items: center;
}
.select-container .ant-select{
    width: 100% !important;
}
