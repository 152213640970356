.group-name,
.gift {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 15px;
}
.gift {
  margin: 30px 0 30px 0;
}
.gift-link {
  margin-left: 10px;
  text-decoration: none;
}
.create-group-button {
  width: 210px;
  height: 110px;
  background: #e4f3ff;
  border: 1.5px dashed #2e6bb4;
  border-radius: 5px;
  margin-top: 32px;
}
.group-container {
  padding-bottom: 25px;
}
.text-common {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}
.group-tab-line {
  background: #2e6bb4;
  border-radius: 5px 5px 0 0;
  height: 5px;
  position: absolute;
  left: 0;
  width: 100%;
  top: calc(100% + 20px);
}
.group-name-detail {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin-top: 32px;
  text-align: center;
}
.group-detail-back {
  margin-left: 17.5px;
}
.group-detail-info {
  width: 100%;
  padding: 25px;
  box-shadow: 0 22px 80px rgba(0, 0, 0, 0.07), 0 4.91399px 17.869px rgba(0, 0, 0, 0.0417275),
    0 1.46302px 5.32008px rgba(0, 0, 0, 0.0282725);
  border-radius: 10px;
  position: relative;
}
.mb-20 {
  margin-bottom: 20px;
}
.ml-13 {
  margin-left: 13px;
}
.fw-700 {
  font-weight: 700;
}
.filter-container {
  margin: 5px 0 20px 0;
}
.add-member-button {
  border-radius: 23px !important;
  padding: 8px 20px !important;
}
.text-modal {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #858585;
}
.group-detail-info-admin {
  width: 50%;
  box-shadow: 0 22px 80px rgba(0, 0, 0, 0.07), 0 4.91399px 17.869px rgba(0, 0, 0, 0.0417275),
    0 1.46302px 5.32008px rgba(0, 0, 0, 0.0282725);
  border-radius: 10px;
  padding: 25px 25px 30px 25px;
}
.group-detail-info-admin-package {
  width: 33.3%;
  box-shadow: 0 22px 80px rgba(0, 0, 0, 0.07), 0 4.91399px 17.869px rgba(0, 0, 0, 0.0417275),
    0 1.46302px 5.32008px rgba(0, 0, 0, 0.0282725);
  border-radius: 10px;
  padding: 25px;
}
.group-detail-info-admin:first-child {
  margin-right: 30px;
}

.group-detail-info .current-package {
  position: absolute;
  left: 0;
  top: 10px;
  background: linear-gradient(160deg, #63acb0, #1d3679);
  width: 150px;
  text-align: center;
  color: white;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: 5px 0;
}

.package {
  margin: 40px 0 30px 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}
.list-package {
  margin-right: -20px;
  column-gap: 15px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.upgrade {
  margin: 18px 0 5px 0;
}
.modal-add-member,
.modal-add-group {
  padding: 18px 30px 38px 30px;
}
.modal-remove-member,
.modal-change-permission,
.modal-remove-video,
.modal-entrust-video {
  padding: 35px 30px 30px 30px;
}
.leave-group-icon {
  width: 320px;
  height: 210px;
}
.add-group-icon {
  width: 24px;
  height: 24px;
}

.title-choose-package {
  font-weight: 600;
  font-size: 20px;
  color: #222230;
  line-height: 24px;
}

.list-package .package-detail {
  border-radius: 16px;
  border: 3px solid #efeff4;
  cursor: pointer;
  user-select: none;
  padding: 14px 22px;
  width: fit-content;
  min-width: 282px;
  margin-top: 16px;
  transition: border-color 0.1s;
  position: relative;
  overflow: hidden;
  width: 320px;
}

.list-package .package-detail:hover {
  border-color: gray;
}

.package-detail.is-active {
  border-color: #0e71eb !important;
  box-shadow: 0 0 5px 0px #0e71eb;
}

.package-detail .package-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: #232333;
  text-align: left;
}

.package-detail .number-user-join {
  text-align: right;
}

.package-detail .number-project {
  font-size: 12px;
  line-height: 24px;
  color: rgba(4, 4, 19, 0.56);
  margin-top: 4px;
}

.package-detail .price-month {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #232333;
  margin-top: 8px;
}

.cycle-payment {
  flex: 1;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: rgba(4, 4, 19, 0.56);
  text-transform: capitalize;
  font-weight: 600;
}

.total-price {
  text-align: left;
  margin-top: 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #232333;
}

.bill {
  text-align: left;
  margin-top: 4px;
  font-size: 12px;
  line-height: 24px;
  color: rgba(4, 4, 19, 0.56);
  white-space: pre-wrap;
}

.packageDesc {
  margin-top: 15px;
}

.button-pay-package {
  text-align: center;
  width: 200px;
  border-radius: 100px;
}

.package-ribbon {
  position: absolute;
  right: -40px;
  top: 20px;
  background: linear-gradient(160deg, #63acb0, #1d3679);
  width: 160px;
  transform: rotate(45deg);
  text-align: center;
  padding: 3px 0;
  color: white;
}

.update-package {
  color: #1d3679;
  cursor: pointer;
}

.update-package:hover {
  text-decoration: underline;
}

.content-account-package {
  height: calc(100vh - 180px);
  overflow-y: auto;
}

.content-account-package::-webkit-scrollbar {
  width: 0px;
}

.margin-horizontal-9 {
  margin: 0 90px;
}

.cursor-pointer {
  cursor: pointer;
}

.package-desc-detail {
  background-color: #ebebeb;
  border-left: 1px solid #cbcbcb;
  padding-top: 10px;
}
