.main {
  display: flex;
  padding: 0 98px 0 98px;
}
.left-main {
  background-color: #f3f3f3;
  padding-top: 30px;
  width: 30%;
  min-height: calc(100vh - 100px);
  border-radius: 10px;
}
.right-main {
  width: 70%;
  min-height: calc(100vh - 100px);
  padding: 25px 0 25px 30px;
}
.avatar-info {
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
  margin: auto;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
.avatar-update {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.avatar-edit {
  top: 50px;
  left: 40px;
  text-align: center;
}
.information {
  margin-left: 30px;
}
.data {
  padding: 0 30px 0 30px;
}
.flex-space-between {
  justify-content: space-between;
}
.text-bold {
  font-weight: 700;
}
.tab {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #858585;
}
.tab-space {
  width: 30px;
}
.tab-active {
  color: #2e6bb4;
}
.tab-line {
  background: #2e6bb4;
  border-radius: 5px 5px 0 0;
  height: 5px;
}
.tab-border {
  border-bottom: 1px solid #c7c7c7;
  padding: 0 0 25px 0;
}
.flex-direct-column {
  flex-direction: column;
}
.form-left {
  width: 25%;
}
.form-right {
  width: 75%;
}
.field-error {
  color: red;
  font-size: 13px;
}
.fl-1 {
  flex: 1;
}
.data-use {
  height: 4px;
  background-color: #2e6bb4;
  width: 30%;
}
.data-left {
  height: 4px;
  background-color: #c7c7c7;
  width: 70%;
}
.information .active {
  color: #2e6bb4;
}
.user-name {
  margin: 15px 0 22px 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.hindPassword {
  color: gray;
  font-size: 11px;
  text-align: right;
  font-style: italic;
}
